@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "../../node_modules/magnific-popup/src/css/main";

html {
	background: $color-black url('../img/D_BG.jpg') no-repeat center bottom;
	background-size: cover;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-size: 16px;
	line-height: 1;
	overflow-x: hidden;

	@include desktop-sm-down {
		background: url('../img/M_BG.jpg') no-repeat center bottom;
		background-size: cover;
	}
}

body {
	color: $color-white;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	height: 100vh;
	overflow: hidden;
	position: relative;

	@include desktop-sm-down {
		display: block;
	}

	> .bg-wrap {
		position: relative;

		> #desktop-bg {
			flex: 0 0 auto;
			height: 100vh;
			object-fit: cover;
			object-position: center top;
			width: auto;

			@include desktop-sm-down {
				display: none;
			}
		}

		> #mobile-bg {
			display: none;

			@include desktop-sm-down {
				display: block;
				position: relative;
				width: 100%;
				z-index: 1;
			}
		}

		> #miley-girl {
			position: absolute;
			right: 30%;
			top: 0;
			transform: rotate(-15deg);
			width: 142px;
			z-index: 10;

			@include desktop-sm-down {
				left: 15px;
				right: auto;
				top: 20px;
			}

			@include phone-sm-down {
				height: 292px;
				width: auto;
			}
		}

		> #web {
			left: 30px;
			position: absolute;
			top: 50%;
			width: 192px;
			z-index: 10;

			@include desktop-sm-down {
				left: auto;
				right: -50px;
				top: 30%;
			}
		}

		> #tattoo {
			bottom: 90px;
			position: absolute;
			right: 25%;
			width: 159px;
			z-index: 10;

			@include desktop-sm-down {
				display: none;
			}
		}

		/*> .sparkle {
			position: absolute;
			z-index: 10;

			@include desktop-sm-down {
				display: none;
			}

			&.sparkle-1 {
				right: 35%;
				top: 0;
				width: rem(272);
			}

			&.sparkle-2 {
				right: 7%;
				top: 26%;
				width: rem(357);
			}

			&.sparkle-3 {
				bottom: 22%;
				right: 22%;
				width: rem(150);
			}

			&.sparkle-4 {
				bottom: -12%;
				right: 2%;
				width: rem(382);
			}
		}*/
	}

	@include desktop-sm-down {
		height: auto;
		overflow: auto;
	}
}

a {
	color: $color-white;

	@include hover-focus {
		color: $color-white;
	}
}

#content {
	flex: 1 1 auto;
	height: 100vh;
	/*margin-left: -150px;*/
	overflow-x: hidden;
	overflow-y: scroll;
	padding: 10px 15px 15px 45px;
	text-align: center;

	@include desktop-sm-down {
		height: auto;
		margin: 0 auto;
		overflow: visible;
		padding: 10px 15px 15px 15px;
		position: relative;
		width: 100%;
		z-index: 2;
	}

	header, main, footer {
		max-width: 500px;

		@include desktop-sm-down {
			margin: 0 auto;
		}
	}

	header {

	}

	main {
		@include desktop-sm-down {
			align-items: center;
			display: flex;
			flex-direction: column;
		}

		.video {
			margin: 40px 0 115px 0;
			position: relative;
			width: 100%;

			@include desktop-sm-down {
				margin: 30px 0 190px 0;
				order: 2;
			}

			.tv {
				display: block;
				overflow: hidden;
				position: relative;
				z-index: 4;


				> .screen {
					bottom: 2%;
					left: 6.8%;
					position: absolute;
					width: 55%;

					.embed-container {
						padding-bottom: 95%;
					}
				}

				> img {
					pointer-events: none;
					position: relative;
					z-index: 15;
				}
			}

			#watch {
				bottom: -65px;
				left: -15px;
				position: absolute;
				width: 178px;
				z-index: 10;

				@include phone-sm-down {
					bottom: -140px;
					left: 0;
				}
			}

			#stars {
				pointer-events: none;
				position: absolute;
				right: -150px;
				top: -115px;
				transform: scaleY(-1) rotate(30deg);
				width: 382px;
				z-index: 50;

				@include desktop-sm-down {
					bottom: -400px;
					top: auto;
					transform: scaleY(1) rotate(0deg);
					z-index: 1;
				}
			}

			#lips-video {
				left: 15px;
				position: absolute;
				top: 45px;
				width: 52px;
				z-index: 10;

				@include desktop-sm-down {
					display: none;
				}
			}

			#prisoner {
				bottom: -90px;
				display: inline-block;
				position: absolute;
				right: -75px;
				width: 352px;
				z-index: 50;

				@media screen and (max-width: 1350px) {
					right: -20px;
				}

				@include phone-sm-down {
					left: 0;
					right: auto;
				}
			}
		}

		.pre-save {
			position: relative;
			@include desktop-sm-down {
				margin-top: -250px;
				order: 1;
			}

			@include phone-sm-down {
				margin-top: -150px;
			}

			> .plastic-hearts {
				margin-bottom: 20px;
				position: relative;

				> #lips-green {
					bottom: -50px;
					position: absolute;
					right: 0;
					width: 49px;

					@include desktop-sm-down {
						display: none;
					}
				}

				> #lips-red {
					left: -30px;
					position: absolute;
					top: 60%;
					transform: rotate(-30deg);
					width: 80px;
					@include desktop-sm-down {
						display: none;
					}
				}

				> #lips-blue {
					display: none;
					left: 0;
					position: absolute;
					top: 55%;
					width: 52px;

					@include desktop-sm-down {
						display: block;
					}
				}

				> #plastic-hearts {
					margin: 0 auto;
				}
			}

			> .connect {
				position: relative;

				> #lips-red-2 {
					display: none;
					left: 0;
					position: absolute;
					top: -5px;
					transform: rotate(-15deg);
					width: 52px;

					@include desktop-sm-down {
						display: block;
					}
				}

				> #lips-green-2 {
					bottom: 0;
					display: none;
					position: absolute;
					right: 0;
					width: 49px;

					@include desktop-sm-down {
						display: block;
					}
				}

				> .btn-drop {
					font-family: "Compacta W04 Bold Italic";
					font-size: 21px;
					margin: 0 auto 14px auto;
					max-width: 200px;
					position: relative;
					text-align: center;
					text-transform: uppercase;

					> a {
						background: $color-pink;
						color: $color-purple;
						display: block;
						padding: 6px 9px;

						i {
							margin-left: 5px;
						}
					}

					> ul {
						display: none;
						left: 0;
						list-style: none;
						margin: 0;
						padding: 0;
						position: absolute;
						right: 0;
						top: 100%;
						z-index: 100;

						> li {
							> a {
								background: $color-pink;
								color: $color-purple;
								display: block;
								padding: 7px 21px;
							}
						}
					}
				}

				> p {
					color: #D4DEE1;
					font-size: 8px;
					margin: 0;
				}
			}
		}
	}

	footer {
		margin-top: 25px;

		.follow {
			@include desktop-sm-down {
				position: relative;
				z-index: 20;
			}

			> span {
				color: $color-white;
				display: block;
				font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
				font-size: 23px;
				font-style: italic;
				font-weight: bold;
				margin-bottom: 17px;
				position: relative;
				text-shadow: 0px 0px 12px $color-black;
				text-transform: uppercase;

				@include tablet-down {
					padding-right: 25px;
				}

				br {
					display: none;

					@include tablet-down {
						display: block;
					}
				}

				@include desktop-sm-down {
					font-size: 32px;
					margin-bottom: 30px;
				}

				@include phone-sm-down {
					text-align: right;
				}

				#tattoo-2 {
					display: none;
					left: 0;
					position: absolute;
					top: -70px;
					width: 120px;

					@include desktop-sm-down {
						display: block;
					}
				}
			}

			> .socials {
				list-style: none;
				margin: 0;
				padding: 0;

				> li {
					display: inline-block;
					font-size: rem(20);
					vertical-align: middle;

					+ li {
						margin-left: rem(25);

						@media screen and (max-width: 376px) {
							margin-left: 20px;
						}
					}

					> a {
						color: $color-pink;

						@include hover-focus {
							color: $color-white;
						}
					}
				}
			}
		}

		form {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			margin: 25px auto 30px auto;
			max-width: 280px;

			@include desktop-sm-down {
				position: relative;
				z-index: 20;
			}

			> div {
				color: $color-gray;
				flex: 0 0 100%;
				font-family: "Compacta W04 Bold Italic";
				font-size: 18px;
				text-align: center;
				text-transform: uppercase;
			}

			> input {
				background: none;
				border: 0;
				border-bottom: 1px solid $color-gray;
				border-radius: 0;
				color: $color-gray;
				display: $color-white;
				flex: 1 1 auto;
				font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
				font-size: 18px;
				font-style: italic;
				font-weight: bold;
				padding: 0 0 7px 0;
				text-transform: uppercase;

				@include placeholder {
					color: $color-gray;
					font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
					font-size: 18px;
					font-style: italic;
					font-weight: bold;
				}

				&:focus {
					border-color: $color-pink;
					outline: 0;
				}
			}

			> button {
				background: $color-pink;
				border: 0;
				color: $color-purple;
				flex: 0 0 auto;
				font-family: "Compacta W04 Bold Italic";
				font-size: 18px;
				line-height: 1;
				margin-left: 12px;
				padding: 6px 13px;
				text-transform: uppercase;

				@include hover-focus {
					background: $color-gray;
					color: $color-purple;
				}
			}
		}

		.enter {
			border-bottom: 1px solid $color-pink;
			display: inline-block;
			font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
			font-size: 22px;
			font-style: italic;
			font-weight: bold;
			padding-bottom: 3px;
			text-shadow: 3px 3px 12px $color-pink;
			text-transform: uppercase;
			vertical-align: middle;
		}

		.copyright {
			color: $color-pink;
			font-size: 8px;
			line-height: line-height(10, 8);
			margin-top: 28px;

			p {
				margin: 0;

				a {
					color: $color-pink;

					@include hover-focus {
						text-decoration: underline;
					}
				}
			}
		}
	}
}

.popup {
	background: $color-black;
	border: 2px solid $color-pink;
	color: $color-gray;
	font-family: "Compacta W04 Bold Italic";
	font-size: 30px;
	margin: 20px auto;
	max-width: 500px;
	padding: 30px;
	position: relative;
	text-align: center;
	text-transform: uppercase;
	width: auto;

	.mfp-close {
		color: $color-gray !important;
	}

	form {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		margin: 25px auto 30px auto;
		max-width: 280px;

		> input {
			background: none;
			border: 0;
			border-bottom: 1px solid $color-gray;
			border-radius: 0;
			color: $color-gray;
			display: block;
			flex: 1 1 auto;
			font-family: "Compacta W04 Bold Italic";
			font-size: 18px;
			padding: 0 0 7px 0;
			text-transform: uppercase;

			@include placeholder {
				color: $color-gray;
				font-family: "Compacta W04 Bold Italic";
				font-size: 18px;
			}

			&:focus {
				border-color: $color-pink;
				outline: 0;
			}
		}

		> button {
			background: $color-pink;
			border: 0;
			color: $color-purple;
			flex: 0 0 auto;
			font-family: "Compacta W04 Bold Italic";
			font-size: 18px;
			line-height: 1;
			margin-left: 12px;
			padding: 6px 13px;
			text-transform: uppercase;

			@include hover-focus {
				background: $color-gray;
				color: $color-purple;
			}
		}
	}
}

.sparkle, .lips {
	pointer-events: none;
	z-index: 2000;
}
